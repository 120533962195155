import { useExperiment } from "../../../../contexts/ExperimentContext";
import styles from "./PanelSpacer.module.scss";

export default function PanelSpacer({ className }) {
  const { experiment } = useExperiment();
  return (
    <div
      className={[
        styles["PanelSpacer"],
        styles["PanelSpacerNew"],
        className,
      ].join(" ")}
    ></div>
  );
}
