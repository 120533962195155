import { t } from "i18next";
import Router from "next/router";
import {
  getContact,
  getValue,
  leadSubmission,
  leadUUID,
  pollLeadCreated,
  resetLead,
} from "./Lead";
import { POSTCONVERT_URL } from "./constants";

function insertAlsoBuying(array, item, index) {
  // Check if key already exists in the array
  const existingIndex = array.indexOf(item);

  if (existingIndex !== -1) {
    // Remove key from its current position
    array.splice(existingIndex, 1);
  }

  // Insert "also-buying" after index 3
  array.splice(index + 1, 0, item);

  return array;
}

function moveArrayElement(arr, fromIndex, toIndex) {
  // Check if fromIndex and toIndex are within bounds
  if (
    fromIndex < 0 ||
    fromIndex >= arr.length ||
    toIndex < 0 ||
    toIndex >= arr.length
  ) {
    throw new Error("Index out of bounds");
  }

  // Remove the element from the original position
  const element = arr.splice(fromIndex, 1)[0];

  // Insert the element at the new position
  arr.splice(toIndex, 0, element);

  return arr;
}

function removeStringFromArray(arr, str) {
  return arr?.filter((item) => item !== str);
}

const routing = (experiment) => {
  let buyer = [
    "/",
    "price",
    "location",
    "timeframe",
    "mortgage",
    "name",
    "email",
    "phone",
    "mrc",
  ];

  let seller = ["/", "price", "property", "address", "name", "email", "phone"];

  let both = [
    "/",
    "price",
    "property",
    "address",
    "also-buying",
    "name",
    "email",
    "phone",
    "mrc",
  ];

  return {
    buyer,
    seller,
    both,
  };
};

export const PROGRESSBAR_ROUTING = (experiment) => {
  const routes = routing(experiment);
  routes.buyer = removeStringFromArray(routes?.buyer, "mrc");
  routes.both = removeStringFromArray(routes?.both, "mrc");

  return routes;
};

export function isNextSubmit() {
  return false;
}

export async function nextPage(experiment) {
  const { curPage, leadType } = getCurrentPage(experiment);
  const nextPage = curPage + 1;
  const nextRoute = routing(experiment)[leadType][nextPage];
  setCompletedStep(experiment);
  const { leadType: routerLeadType, step, ...restQuery } = Router.query;
  Router.push({ pathname: `/${leadType}/${nextRoute}`, query: restQuery });
}

export function pushToAgentsApp(url) {
  // Preserve query parameters from current URL
  const currentParams = new URLSearchParams(window.location.search);
  const targetUrl = new URL(url, window.location.origin);

  // Add all current query params to the target URL
  currentParams.forEach((value, key) => {
    targetUrl.searchParams.append(key, value);
  });

  window.location.href = targetUrl.toString();
}

export function setCompletedStep(experiment) {
  const { curPage, leadType, step } = getCurrentPage(experiment);

  const completedSteps = getCompletedSteps();
  completedSteps[leadType][curPage === 0 ? "/" : step] = true;
  window.localStorage.setItem("completedSteps", JSON.stringify(completedSteps));
}

export function prevPage(experiment) {
  const { curPage, leadType } = getCurrentPage(experiment);
  const nextPage = curPage - 1;
  const nextRoute = routing(experiment)[leadType][nextPage];
  Router.push(nextRoute === "/" ? "/" : `/${leadType}/${nextRoute}`);
}

export function isPageValid(experiment) {
  const { prevStep, leadType, step, lastCompletedStep } =
    getCurrentPage(experiment);

  const completedSteps = getCompletedSteps();

  if (step === "price") {
    window.localStorage.setItem("leadType", leadType);
    completedSteps[leadType][prevStep] = true;
  }

  const pageValid = completedSteps[leadType]
    ? completedSteps[leadType][prevStep]
    : false;

  if (!pageValid && step) {
    Router.replace(
      lastCompletedStep === "/" ? "/" : `/${leadType}/${lastCompletedStep}`
    );
  }
}

export function getStepIndex(experiment) {
  const { curPage } = getCurrentPage(experiment);
  return curPage;
}

export function getCompletedSteps() {
  const completedSteps = window.localStorage.getItem("completedSteps");
  const jsonCompletedSteps = completedSteps
    ? JSON.parse(completedSteps)
    : { seller: {}, buyer: {}, both: {} };

  return jsonCompletedSteps;
}

export const getCurrentPage = (experiment) => {
  if (
    !process.browser ||
    (!Router.query.step && !window.localStorage.getItem("leadType"))
  ) {
    return {};
  }

  const leadType =
    Router.query.leadType || window.localStorage.getItem("leadType");
  const step = Router.query.step || "/";
  const r = Router;

  const currentPath = Router.pathname;
  const curPage =
    currentPath === "/" ? 0 : routing(experiment)[leadType]?.indexOf(step);

  const prevStep =
    currentPath === "/"
      ? "/"
      : routing(experiment)[leadType]
      ? routing(experiment)[leadType][curPage - 1]
      : 0;

  const completedSteps = getCompletedSteps();
  const completedKeys = completedSteps[leadType]
    ? Object.keys(completedSteps[leadType])
    : [];

  let lastCompletedPage = 0;
  for (let i = 0; i < completedKeys.length; i++) {
    const completedPage = routing(experiment)[leadType].indexOf(
      completedKeys[i]
    );
    if (completedPage > lastCompletedPage) {
      lastCompletedPage = completedPage;
    }
  }
  const lastCompletedStep = routing(experiment)[leadType]
    ? routing(experiment)[leadType][lastCompletedPage + 1]
    : "";
  return { curPage, leadType, step, prevStep, lastCompletedStep };
};
