export default function BuyerSellerIcon({
  width = 36,
  height = 37,
  color = "#EA580C",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 36 37`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7031 34.5937C18.7031 35.3704 18.0736 35.9999 17.2969 35.9999H1.40625C0.629517 35.9999 0 35.3704 0 34.5937V15.2345C0 14.817 0.185395 14.4212 0.505921 14.1543L17.0997 0.326087C17.6213 -0.108696 18.3788 -0.108696 18.9004 0.326087L35.4941 14.1543C36.0907 14.6514 36.1712 15.538 35.674 16.1348C35.1769 16.7314 34.29 16.8118 33.6935 16.3147L18 3.2369L2.8125 15.8931V33.1874H17.2969C18.0736 33.1874 18.7031 33.817 18.7031 34.5937Z"
        fill={color}
      />
      <path
        d="M27.0005 23.0391C25.6109 23.0391 24.4805 24.1695 24.4805 25.5591C24.4805 26.9486 25.6109 28.0791 27.0005 28.0791C28.39 28.0791 29.5205 26.9486 29.5205 25.5591C29.5205 24.1695 28.39 23.0391 27.0005 23.0391ZM27.0005 27.018C26.196 27.018 25.5415 26.3635 25.5415 25.5591C25.5415 24.7546 26.196 24.1001 27.0005 24.1001C27.8049 24.1001 28.4594 24.7546 28.4594 25.5591C28.4594 26.3635 27.8049 27.018 27.0005 27.018Z"
        fill={color}
        stroke={color}
        stroke-width="0.48"
      />
      <path
        d="M27.0156 18.7207C23.2355 18.7207 20.1602 21.7961 20.1602 25.5762V25.7657C20.1602 27.6775 21.2562 29.9053 23.418 32.3871C24.9851 34.1863 26.5303 35.4409 26.5953 35.4934L27.0156 35.8331L27.4359 35.4935C27.501 35.4409 29.0462 34.1864 30.6133 32.3872C32.775 29.9053 33.8711 27.6775 33.8711 25.7657V25.5762C33.8711 21.7961 30.7958 18.7207 27.0156 18.7207ZM32.5335 25.7657C32.5335 28.9965 28.3706 32.9074 27.0156 34.0947C25.6602 32.9071 21.4978 28.9963 21.4978 25.7657V25.5762C21.4978 22.5337 23.9731 20.0584 27.0156 20.0584C30.0581 20.0584 32.5335 22.5337 32.5335 25.5762V25.7657Z"
        fill={color}
        stroke={color}
        stroke-width="0.96"
      />
    </svg>
  );
}
