export default function SellerIcon({
  width = 36,
  height = 36,
  color = "#EA580C",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 36 36`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M36 1.30904C36 0.489249 35.3159 -0.000919066 34.6058 1.29378e-06C33.4688 0.00148536 30.137 0.00542374 18.7879 0.00756067C10.977 0.00904776 7.20905 0.00640004 5.42592 0.0039397C4.28442 0.00236496 3.35641 0.911579 3.35641 2.04417L3.34752 33.2444H1.3881C0.621827 33.2444 0.00114293 33.8581 1.56285e-06 34.6155C-0.00113522 35.3733 0.618022 35.9911 1.38526 35.9946C1.96852 35.997 3.17402 36 6.37877 36C9.59199 36 10.7195 35.997 11.2574 35.9944C12.0389 35.9908 12.6423 35.3399 12.6423 34.5983C12.6423 33.8245 12.0055 33.2444 11.2545 33.2444H6.0556L6.06447 2.75448H8.88474L10.8787 2.76025H14.0907V6.88727H10.3932C9.36329 6.88727 8.51029 7.63344 8.35591 8.60945C8.33944 8.71348 8.33099 8.81985 8.33105 8.92781C8.33251 11.1759 8.33684 13.4239 8.34118 15.6715V15.6731V15.6747L8.3461 18.304C8.34848 19.6762 8.35044 21.0483 8.35133 22.4204C8.35205 23.5467 9.27525 24.4603 10.4145 24.4603H33.8117C34.9528 24.4603 35.8704 23.5449 35.8704 22.4201V8.92748C35.8704 7.80073 34.947 6.88727 33.8077 6.88727H30.1408V2.76025H34.6073C35.4318 2.76025 36 2.01199 36 1.30904ZM16.7988 6.88727V2.76025H27.4329V6.88727H16.7988ZM11.025 21.7045V9.64297H33.2066V21.7045H11.025Z"
        fill={color}
      />
    </svg>
  );
}
