export default function BuyerIcon({
  width = 36,
  height = 36,
  color = "#EA580C",
}) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 36 36`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7031 34.5937C18.7031 35.3704 18.0736 35.9999 17.2969 35.9999H1.40625C0.629517 35.9999 0 35.3704 0 34.5937V15.2345C0 14.817 0.185395 14.4212 0.505921 14.1543L17.0997 0.326087C17.6213 -0.108696 18.3788 -0.108696 18.9004 0.326087L35.4941 14.1543C36.0907 14.6514 36.1712 15.538 35.674 16.1348C35.1769 16.7314 34.29 16.8118 33.6935 16.3147L18 3.2369L2.8125 15.8931V33.1874H17.2969C18.0736 33.1874 18.7031 33.817 18.7031 34.5937ZM33.7739 30.7894C32.2309 32.6497 29.9268 34.3528 26.9253 35.8519C26.5295 36.0494 26.0643 36.0494 25.6685 35.8519C22.667 34.3528 20.3629 32.6497 18.8201 30.7894C14.2399 25.268 17.4293 19.1742 22.1485 19.1742C24.029 19.1742 25.4125 19.944 26.2969 20.6666C27.1813 19.944 28.5648 19.1742 30.4453 19.1742C35.1736 19.1742 38.3426 25.2814 33.7739 30.7894ZM30.4453 21.9866C28.4999 21.9866 27.4788 23.4451 27.4689 23.4596C26.8849 24.3405 25.6537 24.2866 25.1285 23.4654C25.0423 23.3443 24.0326 21.9866 22.1485 21.9866C19.1173 21.9866 16.5138 27.8281 26.2969 33.0125C36.08 27.8281 33.477 21.9866 30.4453 21.9866Z"
        fill={color}
      />
    </svg>
  );
}
