import Bugsnag from "@bugsnag/js";
import { useRouter } from "next/router";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useExperiment } from "/contexts/ExperimentContext";
import {
  createFunnelEvent,
  createFunnelRequest,
  saveAdData,
  setReferrer,
} from "../config/Analytics";
import { resetContact, resetLead, setSessionUUID } from "../config/Lead";
import { useInactivityTracking } from "../hooks/useInactivityTracking";
// Styles
import { nextPage } from "../config/PageRouting";
import { ASSET_BASE_URL } from "../config/constants";
import { generateApiUrl } from "../config/fetch";
import { generateLocation } from "../utils/location";

import Borderless from "/components/experiments/borderless/index";
import Reskin from "/components/experiments/reskin/index";

import { EXPERIMENT_ENUM } from "../contexts/ExperimentContext";

export default function Home({ locationData, sessionUUID, server }) {
  const { t } = useTranslation(["translation", "common"]);
  const router = useRouter();
  const { experiment, loaded } = useExperiment();

  const serverLocation = () => {
    let locale = "";

    if (locationData.city) {
      locale += locationData.city;
    }

    if (locationData.state) {
      locale += `, ${locationData.state}`;
    }
    return locale;
  };

  function capitalizeWords(text) {
    return text.replace(/\b\w/g, function (match) {
      return match.toUpperCase();
    });
  }

  const normalizeUtmCity = useCallback((city) => {
    return capitalizeWords(city.replace("_", " "));
  }, []);

  const chosen_locale =
    locationData?.location_type === "interest_loc"
      ? serverLocation()
      : router.query.utm_state && router.query.utm_city
      ? normalizeUtmCity(router.query.utm_city) +
        ", " +
        router.query.utm_state.toLocaleUpperCase()
      : locationData
      ? serverLocation()
      : router.query.utm_city
      ? normalizeUtmCity(router.query.utm_city)
      : null;

  const [chosenLocale, setChosenLocale] = useState(chosen_locale);
  const [agentTaxonomy, setAgentTaxonomy] = useState("Realtors");
  const funnelRequestCreated = useRef(false);

  const getAgentTaxonomy = useCallback(() => {
    const agentTaxonomyMapping = {
      realtor: "Realtor",
      "real estate agent": "Real Estate Agent",
      broker: "Real Estate Broker",
      realty: "Realty Agent",
      company: "Real Estate Company",
      companies: "Real Estate Company",
      agency: "Real Estate Agency",
      agencies: "Real Estate Agency",
      "listing agent": "Listing Agent",
      agent: "Real Estate Agent",
      "real estate": "Real Estate Expert",
    };

    const mappingKeys = Object.keys(agentTaxonomyMapping);
    let taxonomy = "REALTORS®";

    for (let i = 0; i < mappingKeys.length; i++) {
      const curKey = mappingKeys[i];
      const utmTerm =
        typeof router?.query?.utm_term === "string"
          ? router.query.utm_term
          : "";
      if (utmTerm.toLowerCase().includes(curKey)) {
        taxonomy = agentTaxonomyMapping[curKey];
        break;
      }
    }

    return router?.query?.taxonomy || taxonomy;
  }, [router]);

  useEffect(() => {
    const taxonomy = getAgentTaxonomy();
    setAgentTaxonomy(taxonomy);
  }, [setAgentTaxonomy, getAgentTaxonomy]);

  const generateChosenLocale = useCallback(() => {
    const generateCityState = () => {
      if (locationData?.canonical) {
        window.localStorage.setItem("buyerAutofill", locationData?.canonical);
      }
      return (
        normalizeUtmCity(router.query.utm_city) +
        ", " +
        router.query.utm_state.toLocaleUpperCase()
      );
    };

    const chosenLocale =
      locationData?.location_type === "interest_loc"
        ? generateLocation(locationData)
        : router.query.utm_state && router.query.utm_city
        ? generateCityState()
        : generateLocation(locationData);

    return (
      chosenLocale ||
      (router.query.utm_city && normalizeUtmCity(router.query.utm_city))
    );
  }, [locationData, router.query, normalizeUtmCity]);

  useEffect(() => {
    const chosen_locale = generateChosenLocale();
    setChosenLocale(chosen_locale);
  }, [locationData, router.query, generateChosenLocale]);

  const setLeadType = (leadType) => {
    window.localStorage.setItem("leadType", leadType);
    nextPage(experiment);
  };

  const setOriginPath = () => {
    if (!window.localStorage.getItem("originPath")) {
      window.localStorage.setItem(
        "originPath",
        ASSET_BASE_URL + router.asPath.split("?")[0]
      );
    }
  };

  useEffect(() => {
    (function () {
      var js = window.document.createElement("script");
      js.src = "https://www.shopperapproved.com/seals/certificate.js";
      js.type = "text/javascript";
      document.getElementsByTagName("head")[0].appendChild(js);
    })();
  });

  useEffect(() => {
    const params = new URL(document.location).searchParams;
    if (
      params.get("reset_lead") &&
      process.env.NEXT_PUBLIC_ENV !== "production"
    ) {
      resetContact();
      resetLead();
    }
    window.localStorage.removeItem("leadUUID");
    window.localStorage.removeItem("completedSteps");
    setSessionUUID({ _sessionUUID: sessionUUID, force: true });
    setOriginPath();
    saveAdData(router.query);
    const referrer = router.query.referrer
      ? router.query.referrer
      : document.referrer;
    setReferrer(referrer);

    // Initializing the funnel based on lead_type param
    const leadType = params.get("lead_type");
    if (leadType && { buyer: true, seller: true, both: true }[leadType]) {
      setLeadType(leadType);
      return;
    }

    window.localStorage.removeItem("leadType");
  });

  useInactivityTracking({
    step: "landing",
    experiment,
    path: router.asPath,
    isLoaded: loaded,
  });

  useEffect(() => {
    if (!funnelRequestCreated.current && loaded) {
      const chosenLocale = generateChosenLocale();
      const agentTaxonomy = getAgentTaxonomy();
      const headline = `${t(
        `headline_alternate${window.innerWidth < 1024 ? "_mobile" : ""}`,
        {
          chosenLocale,
          agent_taxonomy: agentTaxonomy,
        }
      )} ${chosenLocale ? t("in") + " " + chosenLocale : t("for_you")}`;
      createFunnelRequest({
        page: ASSET_BASE_URL + router.asPath.split("?")[0],
        request_type: "GET",
        parameters: {
          headline,
        },
      });
      createFunnelEvent({
        details: {
          headline,
        },
        step: "landing",
        eventType: "landing-headline",
        expid: experiment,
      });
      funnelRequestCreated.current = true;
    }
  }, [
    loaded,
    router.asPath,
    generateChosenLocale,
    t,
    agentTaxonomy,
    getAgentTaxonomy,
    experiment,
  ]);

  if (!loaded) {
    return null;
  }

  // Test removing the borderless landing page
  // if (experiment.includes("borderless")) {
  //   return (
  //     <Borderless
  //       agentTaxonomy={agentTaxonomy}
  //       chosenLocale={chosenLocale}
  //       setLeadType={setLeadType}
  //     />
  //   );
  // }

  return (
    <Reskin
      agentTaxonomy={agentTaxonomy}
      chosenLocale={chosenLocale}
      setLeadType={setLeadType}
    />
  );
}

export async function getServerSideProps(context) {
  const params = context.query;
  const querystring = Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");

  let json = null;
  try {
    const res = await fetch(generateApiUrl(`geo/google/locale?${querystring}`));
    json = await res.json();
  } catch (e) {
    console.log(e);
  }

  const expid = context.req.cookies[EXPERIMENT_ENUM];

  const sessionUUID = setSessionUUID({
    server: true,
    query: params,
    cookies: context.req.cookies,
    res: context.res,
  });

  try {
    const { req } = context;

    // Construct the full URL
    const protocol = req.headers["x-forwarded-proto"] || "http";
    const host = req.headers["host"];
    const fullUrl = `${protocol}://${host}${req.url}`;

    createFunnelRequest({
      page: ASSET_BASE_URL + context.resolvedUrl.split("?")[0],
      fullUrl,
      sessionUUID,
      leadUUID: null,
      request_type: "SERVER_GET",
      params,
      expid,
      userAgent: context.req.headers["user-agent"],
      server: true,
    });
  } catch (e) {
    console.log(e);
    Bugsnag.notify(e);
  }

  return {
    props: {
      sessionUUID,
      server: true,
      locationData: json,
    },
  };
}
